
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import moment from "moment";
import { Action } from "vuex-module-decorators";

export default defineComponent({
  name: "admin-user",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const { t } = useI18n();
    const store = useStore();

    const validationSchema = Yup.object().shape({
      profile: Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Please enter a valid Email")
          .nullable()
          .label("Email"),
      }),
    });

    const profile = {
      id: null,
      name: null,
      email: null,
      organisation_id: null,
      roles: [],
      role: "",
      enabled: null,
    };

    const actives = [
      {
        label: "Active",
        value: 1,
      },
      {
        label: "Inactive",
        value: 0,
      },
    ];

    const user = {
      organisation_id: null,
      advisory_offices: [] as any,
    };

    const setAdvOffice = {
      organisation_id: null,
      date: null,
    };

    const raw_id = null;

    const branches = [];
    const advOfficesFromDB = null;
    const advOffices = [];
    const isAdvOfficesExist = false;
    const isAdvisoriOfficesSet = false;

    const roles = [
      {
        label: "Management",
        value: 7,
      },
      {
        label: "Branch Head",
        value: 5,
      },
      {
        label: "Financial Advisor",
        value: 4,
      },

      {
        label: "Viewer",
        value: 6,
      },
    ];

    return {
      moment: moment,
      i18n: useI18n(),
      route: useRoute(),
      store,
      raw_id,
      profile,
      branches,
      advOffices,
      validationSchema,
      advOfficesFromDB,
      setAdvOffice,
      isAdvOfficesExist,
      isAdvisoriOfficesSet,
      user,
      roles,
      actives,
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("User", ["Administration"]);

      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });

      this.initProfile();
    },
    // imageOnChange(event) {
    //   this.profile.image_url = URL.createObjectURL(event.target.files[0]);
    // },
    initProfile() {
      this.store
        .dispatch(Actions.ADMIN_GET_USER_FAS, this.route.params.user_id)
        .then(() => {
          const data = this.store.getters.getUserFASData;

          console.log("data");
          console.log(data);

          const form = this.$refs.form as any;
          this.profile = {
            name: data.name,
            id: data.id,
            enabled: data.enabled,
            email: data.email,
            organisation_id: data.organisation_id ?? null,
            roles: data?.roles ?? [],
            role: "select",
          };

          this.profile.enabled == 2;
          this.profile.roles.forEach((role) => {
            this.profile.role = role.id;
          });

          if (this.profile.role !== "select") {
            this.profile.enabled == 1;
          } else {
            this.profile.enabled == 2;
          }
        });
    },
    onActiveChange() {
      if (this.profile.enabled == 2) {
        this.profile.role = "select";
      }
    },
    onSubmit() {
      if (this.profile.enabled == 1 && this.profile.role == "select") {
        Swal.fire({
          text: "Please select role",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }

      this.store
        .dispatch(Actions.ADMIN_UPDATE_USER_FAS, this.profile)
        .then(() => {
          Swal.fire({
            text: "User Details have been updated",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            this.initProfile();
          });
        });
    },
  },
});
